<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the following play significant roles in the extent of injury or harm when exposed
        to a hazardous chemical? Select all that apply.
      </p>

      <p class="mb-0" v-for="question in questionsShuffled" :key="question.inputName">
        <v-checkbox v-model="inputs.studentAnswers" :value="question.value" class="pl-4 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="question.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LC_Q4S2_Q10',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      questions: [
        {text: 'The amount of chemical one is exposed to', value: 'a'},
        {text: 'The amount of time one is exposed to the chemical', value: 'b'},
        {text: 'How the chemical entered the body', value: 'c'},
        {text: 'The atmospheric pressure', value: 'd'},
        {text: 'The availability of safety equipment', value: 'e'},
        {text: 'The relative humidity', value: 'f'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    questionsShuffled() {
      return seededShuffle(this.questions, this.seed);
    },
  },
};
</script>
